import { Link } from "gatsby";
import Logo from "../images/logo_2.svg";

const navigation = [
  { name: "início", href: "/" },
  { name: "livros", href: "/livros/" },
  { name: "pessoas", href: "/pessoas/" },
  { name: "top 100", href: "/top/todos" },
];
import SearchModal from "./searchmodal";

function Navbar() {
  return (
    <header>
      <nav className="mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full py-6 flex items-center justify-center lg:justify-between border-b border-gray-500 lg:border-none">
          <div className="flex items-center">
            <Link to="/">
              <span className="sr-only">Um Bom Livro</span>
              <img className="h-10 w-auto" src={Logo} alt="" />
            </Link>
            <SearchModal className="ml-10 md:hidden text-indigo-darker" />
            <div className="hidden ml-10 space-x-8 lg:block">
              {navigation.map((link) => (
                <Link
                  key={link.name}
                  to={link.href}
                  className="font-medium text-2xl text-blue-800 hover:text-blue-900"
                >
                  {link.name}
                </Link>
              ))}
            </div>
            <SearchModal className="hidden ml-10 md:flex" />
          </div>
        </div>
        <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden ">
          {navigation.map((link) => (
            <Link
              key={link.name}
              to={link.href}
              className="font-medium text-blue-800  hover:text-blue-900 "
            >
              {link.name}
            </Link>
          ))}
        </div>
      </nav>
    </header>
  );
}

// function Navbar() {
//   return (
//     <nav className="grid grid-cols-1">
//       <div>
//         <ul className="flex flex-row space-x-4 text-2xl text-blue-900 font-light py-10">
//           <li>
//             <Link to="/">
//               <img className="h-10" alt="logo" src={Logo} />
//             </Link>
//           </li>
//           <li>
//             <Link to="/">início</Link>
//           </li>
//           <li>
//             <Link to="/livros">livros</Link>
//           </li>
//           <li>
//             <Link to="/pessoas">pessoas</Link>
//           </li>

//           <li>
//             <Link to="/top/todos">top 100</Link>
//           </li>
//         </ul>
//       </div>
//     </nav>
//   );
// }

export default Navbar;
