import { useState } from "react";
import { useFlexSearch } from "react-use-flexsearch";

import { useStaticQuery, graphql, Link } from "gatsby";
import { ArrowRight, Users } from "react-feather";
const Search = () => {
  const data = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `);

  const searchIndex = data.localSearchPages.index;
  const store = data.localSearchPages.store;
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(null);

  const results = useFlexSearch(query, searchIndex, store, { limit: 5 });

  const search_func = (new_input) => {
    setLoading(true);

    setQuery(new_input);

    setLoading(false);
  };
  const onChangeHandler = (e) => {
    search_func(e.target.value);
  };

  return (
    <div>
      <input
        value={query}
        onChange={onChangeHandler}
        placeholder="Pesquise..."
        className="text-center bg-gray-100 border-2 border-gray-200 rounded-lg py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      />

      <ul className="flex flex-col ">
        {results.map((result, ind) => (
          <li key={ind} className="p-3 bg-gray-50 mt-2 ">
            <Link
              to={`/pessoas/${result.person_code}`}
              className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150 capitalize"
            >
              <Users
                className="flex-shrink-0 h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
              <span className="ml-4 text-gray-500 font-light">
                {result.person_name}
              </span>
              <span className="flex flex-row justify-end flex-auto text-blue-800">
                <ArrowRight className="ml-1" />
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Search;
